import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-44d09798"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { class: "dropdown-item" }
const _hoisted_5 = { class: "l-container" }
const _hoisted_6 = { class: "p-grid--charts" }
const _hoisted_7 = { class: "p-grid--graphics" }
const _hoisted_8 = { class: "p-grid--chats" }
const _hoisted_9 = { class: "patient-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_AppHeading = _resolveComponent("AppHeading")!
  const _component_TotalsSummary = _resolveComponent("TotalsSummary")!
  const _component_PatientAnalyticsChart = _resolveComponent("PatientAnalyticsChart")!
  const _component_NutritionalPlansChart = _resolveComponent("NutritionalPlansChart")!
  const _component_NewPatientsGreeting = _resolveComponent("NewPatientsGreeting")!
  const _component_LastChats = _resolveComponent("LastChats")!
  const _component_LoadingComponent = _resolveComponent("LoadingComponent")!
  const _component_GetRequestError = _resolveComponent("GetRequestError")!
  const _component_EmptyState = _resolveComponent("EmptyState")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AppHeading, {
      title: _ctx.$t('dashboard.title'),
      headingIcon: 'icon-bar_chart_circle',
      titleBold: _ctx.selectedPatientName
    }, {
      right: _withCtx(() => [
        (_ctx.isAdminUser)
          ? (_openBlock(), _createBlock(_component_Dropdown, {
              key: 0,
              id: "specialist",
              modelValue: _ctx.professionalId,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.professionalId) = $event)),
              options: _ctx.specialists,
              optionValue: "professionalId",
              placeholder: _ctx.$t('plan.select-specialist'),
              class: "professional-dropdown"
            }, {
              value: _withCtx((slotProps) => [
                (slotProps.value)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.specialists?.find((specialist) => specialist.professionalId == slotProps.value)?.name) + " " + _toDisplayString(_ctx.specialists?.find((specialist) => specialist.professionalId == slotProps.value)?.surname), 1))
                  : (slotProps.value === 'global')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t('dashboard.global')), 1))
                    : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(slotProps.placeholder), 1))
              ]),
              option: _withCtx((slotProps) => [
                _createVNode(_component_Avatar, {
                  image: slotProps.option.picture,
                  class: "p-mr-2",
                  shape: "circle"
                }, null, 8, ["image"]),
                _createElementVNode("span", _hoisted_4, _toDisplayString(slotProps.option.name) + " " + _toDisplayString(slotProps.option.surname), 1)
              ]),
              _: 1
            }, 8, ["modelValue", "options", "placeholder"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["title", "titleBold"]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_TotalsSummary, { professionalId: _ctx.professionalId }, null, 8, ["professionalId"]),
          _createVNode(_component_PatientAnalyticsChart, { professionalId: _ctx.professionalId }, null, 8, ["professionalId"]),
          _createVNode(_component_NutritionalPlansChart, { professionalId: _ctx.professionalId }, null, 8, ["professionalId"])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_NewPatientsGreeting, { professionalId: _ctx.professionalId }, null, 8, ["professionalId"]),
          _createVNode(_component_LastChats, { professionalId: _ctx.professionalId }, null, 8, ["professionalId"])
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        (_ctx.isLoadingPatients)
          ? (_openBlock(), _createBlock(_component_LoadingComponent, {
              key: 0,
              "spinner-color": "lavender-700"
            }))
          : (_ctx.isPatientsRequestErrorful)
            ? (_openBlock(), _createBlock(_component_GetRequestError, { key: 1 }))
            : (_ctx.isPatientsListEmpty)
              ? (_openBlock(), _createBlock(_component_EmptyState, {
                  key: 2,
                  heading: _ctx.$t('patients.no-data-title'),
                  icon: "icon-alarm",
                  text: _ctx.$t('patients.no-data-message'),
                  textAtBottom: true
                }, null, 8, ["heading", "text"]))
              : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}